@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

:host {
  text-align: left;

  .timer {
    display: flex;
  }

  .expired {
    font-size: 0.85rem;
    text-align: center;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
    color: $white;
    padding: 4px;
  }

  .timer-item {
    margin-right: 0.25rem;
    font-size: 1rem;

    &:last-of-type {
      margin-right: 0;
    }

    b {
      display: block;
      margin-bottom: 1px;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      background-color: rgba(50, 50, 50, 0.85);
      color: $white;
      text-align: center;
      font-size: 1.15rem;
      padding: 4px 4px 2px;
      line-height: 1;
      min-width: 40px;
    }

    span {
      display: block;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background-color: rgba(0, 0, 0, 0.85);
      color: $white;
      text-transform: uppercase;
      text-align: center;
      padding: 4px 4px 2px;
      line-height: 1;
      min-width: 40px;
      font-size: 0.8em;
    }
  }
}

:host([small]) {
  .timer-item {
    margin-right: 0.15rem;
    font-size: 0.8rem;

    b {
      border-top-left-radius: 0.125rem;
      border-top-right-radius: 0.125rem;
      font-size: 1rem;
      padding: 2px 2px 1px;
      min-width: 20px;
    }

    span {
      border-bottom-left-radius: 0.125rem;
      border-bottom-right-radius: 0.125rem;
      padding: 2px 2px 1px;
      min-width: 20px;
      font-size: 0.8em;
    }
  }
}
